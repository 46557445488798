import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../components/common/Select/Select";
import Datepicker from "../../../../components/Datepicker/datepicker";
import "../../VehiclePopulation.scss";
import intervalPreIcon from "../../assets/select-metric-icon.svg";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import moment from "moment";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";

const PrognosticTop10NonDtc = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [defaultMetric, setDefaultMetric] = useState("Miles");
  const [chartData, setChartData] = useState(null);
  const interval = ["Miles", "Date"];
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  //const [inputData, setInputData] = useState("");
  const [upperLimitData, setUpperLimitData] = useState(5000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [paramData, setParamData] = useState(null);

  const prepareChartData = (dataSet) => {
    getChartData(dataSet);
  };

  const getOutputData = (data) => {
    const milesData = [];
    const detailsData = [];
    let xAxisLabel = data.informationDto.xAxisName;
    let yAxisLabel = data.informationDto.yAxisName;
    let graphName = data.informationDto.graphName;
    data.topTenNonDtcsDto.forEach((val) => {
      detailsData.push({ y: val.noOfOccurrences, values: val.nonDtc });
    });
    milesData.push({
      data: detailsData,
      xLabel: xAxisLabel,
      yLabel: yAxisLabel,
      id: graphName,
    });
    setChartData(milesData);
  };

  const getChartData = async (response) => {
    getOutputData(response);
  };

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setParamData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  }, []);

  const getPageData = (val) => {
    setNoDataFound(false);
    const url = `${VehiclePopulationDashBoard.top_10_Non_dtc}/1`;
    let params = {};
    const rul = localStorage.getItem("rul");
    let lowerLimitDataFromLS = localStorage.getItem("lowerLimitData");
    let upperLimitDataFromLS = localStorage.getItem("upperLimitData");
    if (rul !== null) {
      switch (rul) {
        case "Miles": {
          switch (defaultInterval) {
            case "Miles": {
              delete paramData.type;
              params = {
                fromRulMiles:
                  lowerLimitDataFromLS !== null
                    ? parseInt(lowerLimitDataFromLS)
                    : 1,
                toRulMiles:
                  upperLimitDataFromLS !== null
                    ? parseInt(upperLimitDataFromLS)
                    : 5000,
                rul: "by_miles",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
              break;
            }

            case "Date": {
              delete paramData.type;
              params = {
                fromRulMiles: parseInt(lowerLimitDataFromLS),
                toRulMiles: parseInt(upperLimitDataFromLS),
                rul: "by_miles",
                fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
                type: "by_date",
              };
              break;
            }

            default: {
              params = {
                fromRulMiles: parseInt(lowerLimitDataFromLS),
                toRulMiles: parseInt(upperLimitDataFromLS),
                rul: "by_miles",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
            }
          }
          break;
        }

        case "Engine Run Time": {
          switch (defaultInterval) {
            case "Miles": {
              delete paramData.type;
              params = {
                fromRulEngineRunTime: parseInt(lowerLimitDataFromLS),
                toRulEngineRunTime: parseInt(upperLimitDataFromLS),
                rul: "by_engine_runtime",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
              break;
            }

            case "Date": {
              delete paramData.type;
              params = {
                fromRulEngineRunTime: parseInt(lowerLimitDataFromLS),
                toRulEngineRunTime: parseInt(upperLimitDataFromLS),
                rul: "by_engine_runtime",
                fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
                type: "by_date",
              };
              break;
            }

            default: {
              params = {
                fromRulEngineRunTime: parseInt(lowerLimitDataFromLS),
                toRulEngineRunTime: parseInt(upperLimitDataFromLS),
                rul: "by_engine_runtime",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
            }
          }
          break;
        }

        case "Hours": {
          switch (defaultInterval) {
            case "Miles": {
              delete paramData.type;
              params = {
                fromRulHours: parseInt(lowerLimitDataFromLS),
                toRulHours: parseInt(upperLimitDataFromLS),
                rul: "by_hours",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
              break;
            }

            case "Date": {
              delete paramData.type;
              params = {
                fromRulHours: parseInt(lowerLimitDataFromLS),
                toRulHours: parseInt(upperLimitDataFromLS),
                rul: "by_hours",
                fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
                type: "by_date",
              };
              break;
            }

            default: {
              params = {
                fromRulHours: parseInt(lowerLimitDataFromLS),
                toRulHours: parseInt(upperLimitDataFromLS),
                rul: "by_hours",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
            }
          }
          break;
        }

        case "Key starts": {
          switch (defaultInterval) {
            case "Miles": {
              delete paramData.type;
              params = {
                fromRulKeyStarts: parseInt(lowerLimitDataFromLS),
                toRulKeyStarts: parseInt(upperLimitDataFromLS),
                rul: "by_key_starts",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
              break;
            }

            case "Date": {
              delete paramData.type;
              params = {
                fromRulKeyStarts: parseInt(lowerLimitDataFromLS),
                toRulKeyStarts: parseInt(upperLimitDataFromLS),
                rul: "by_key_starts",
                fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
                type: "by_date",
              };
              break;
            }

            default: {
              params = {
                fromRulKeyStarts: parseInt(lowerLimitDataFromLS),
                toRulKeyStarts: parseInt(upperLimitDataFromLS),
                rul: "by_key_starts",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
            }
          }
          break;
        }

        default: {
          switch (defaultInterval) {
            case "Miles": {
              delete paramData.type;
              params = {
                fromRulMiles:
                  lowerLimitDataFromLS !== null
                    ? parseInt(lowerLimitDataFromLS)
                    : 1,
                toRulMiles:
                  upperLimitDataFromLS !== null
                    ? parseInt(upperLimitDataFromLS)
                    : 5000,
                rul: "by_miles",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
              break;
            }

            case "Date": {
              delete paramData.type;
              params = {
                fromRulMiles: parseInt(lowerLimitDataFromLS),
                toRulMiles: parseInt(upperLimitDataFromLS),
                rul: "by_miles",
                fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
                type: "by_date",
              };
              break;
            }

            default: {
              params = {
                fromRulMiles:
                  lowerLimitDataFromLS !== null
                    ? parseInt(lowerLimitDataFromLS)
                    : 1,
                toRulMiles:
                  upperLimitDataFromLS !== null
                    ? parseInt(upperLimitDataFromLS)
                    : 5000,
                rul: "by_miles",
                fromMiles: lowerLimitData,
                toMiles: upperLimitData,
                type: "by_miles",
              };
            }
          }
        }
      }
    } else {
      switch (defaultInterval) {
        case "Miles": {
          delete paramData.type;
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
          break;
        }

        case "Date": {
          delete paramData.type;
          params = {
            fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
            toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
            type: "by_date",
          };
          break;
        }

        default: {
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
        }
      }
    }

    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    let filteredValues = {};
    if (Object.keys(FilterSelectedValues).length === 0) {
      filteredValues = paramData;
    } else {
      filteredValues = FilterSelectedValues;
    }

    let data = Object.assign({ ...filteredValues }, { ...params });
    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data);
        }
        if (response.status === 401) {
          setApiError(response.data?.details || "You are not authorized.");
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiError(err.response.data?.details || "You are not authorized.");
          setNoDataFound(true);
        } else {
          setNoDataFound(true);
        }
      });
  };
  useEffect(() => {
    if (paramData !== null) getPageData();
  }, [
    defaultInterval,
    lowerLimitData,
    upperLimitData,
    selectedStartDate,
    selectedEndtDate,
    paramData,
  ]);

  const handleOnStartDateChange = (dates) => {
    setSelectedStartDate(dates);
  };

  const handleOnEndDateChange = (dates) => {
    setSelectedEndDate(dates);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);

        // setType(null);
        //   setType("dateTime");
        break;
      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());

        //   setInputData(1);
        //   setType(null);
        break;
      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  if (chartData === null && noDataFound === false) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  const handleLowerRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setLowerLimitData(value);
    }
  };

  const handleUpperRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setUpperLimitData(value);
    }
  };

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        <div
          className="page-options-container metric-box"
          style={{ zIndex: 1 }}
        >
          <Select
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            secondaryContainerClass="top-10-input-box"
            containerClass="top-10-dtc"
            preIcon={intervalPreIcon}
          />
          {showInput && (
            <>
              <input
                type="text"
                className="Dtc-value-input "
                placeholder={`Enter Lower Limit`}
                value={lowerLimitData}
                onChange={handleLowerRulInput}
                maxLength={10}
              />
              <input
                type="text"
                className="Dtc-value-input"
                placeholder={`Enter Upper Limit`}
                value={upperLimitData}
                onChange={handleUpperRulInput}
                maxLength={10}
              />
            </>
          )}
          {showDatepicker && (
            <div
              style={{
                display: "flex",
                position: "relative",
                bottom: "5px",
              }}
            >
              <Datepicker
                onChange={handleOnStartDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />

              <Datepicker
                onChange={handleOnEndDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />
            </div>
          )}
        </div>
        {!noDataFound ? (
          <>
            <BarGraph chartdata={chartData} />
          </>
        ) : (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        )}
      </div>
      {noDataFound === false && (
        <div className="graph-name">Top 10 Non-DTC </div>
      )}
    </>
  );
};

export default PrognosticTop10NonDtc;
