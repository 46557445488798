import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowYAxis2 from "../../../../assets/arrow_second_axis.svg";
import arrowXAxis from "../../../../assets/arrow-xaxis.svg";
import "../../HealthCheck/index.scss";

export const DualLineGraph = (props) => {
  const data = props?.data;
  const categories = data[0]?.categories;
  const xAxisLabel = data[0]?.graphLabels[0]?.xAxisLabel;
  const y1AxisLabel = data[0]?.graphLabels[0]?.y1AxisLabel;
  const y2AxisLabel = data[0]?.graphLabels[0]?.y2AxisLabel;
  const thresholdLine = data[0]?.graphLabels[0]?.thresholdValue;
  const id = props.id;
  const P1data = data[0]?.data[0];
  const P2data = data[0]?.data[1];
  const P3data = data[0]?.data[2];
  const P4data = data[0]?.data[3];
  const P1colordata = data[0]?.data[4];
  const P2colordata = data[0]?.data[5];
  const P3colordata = data[0]?.data[6];
  const P4colordata = data[0]?.data[7];
  const P5data = data[0]?.data[3];
  const P5colordata = data[0]?.data[7];
  let graphPointName = "";

  //console.log("P2colordata",P2colordata)

  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, data, xAxisLabel, y1AxisLabel, y2AxisLabel);
  }, [isSidemenuExpanded, data, P1data, P2data]);

  const getPrimaryYAxisTitle = () => {
    return data?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${y1AxisLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getSecondaryYAxisTitle = () => {
    return data.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend" style="padding-right: 50px;"><div>${y2AxisLabel}</div><div class="y-axis-arrow" ></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
            paddingRight: "30px",
          },
          align: "high",
          useHTML: true,
          offset: 60,
          rotation: -90,
          y: 130,
        };
  };

  const getxAxisTitle = () => {
    return data?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xAxisLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (xAxisLabel, y1AxisLabel, y2AxisLabel, data) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 10],
          borderColor: "#CECECE",
          borderRadius: 20,
        },
        title: {
          text: null,
        },

        xAxis: {
          min: 0,
          categories: categories,
          title: getxAxisTitle(),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
        },

        yAxis: [
          {
            title: getPrimaryYAxisTitle(data, y1AxisLabel),
            min: 0,
            max: thresholdLine,
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },

            gridLineColor: "#F2F2F2",
            plotLines: [
              {
                color: "#ffcccc",
                value: thresholdLine, //chartData?.[1]?.data?.[1]?.y,
                width: 2,
                dashStyle: "dash",
              },
            ],
          },
          {
            title: getSecondaryYAxisTitle(data, y1AxisLabel),
            opposite: true,
            min: 0,
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },

            gridLineColor: "#F2F2F2",
          },
        ],

        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: function () {
                  if (
                    this.color == "#6FAC4E" ||
                    this.color == "#B9D6AA" ||
                    this.color === "#165C0B"
                  ) {
                    graphPointName = "green";
                  } else {
                    graphPointName = "blue";
                  }
                },
              },
            },
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              align: "right",
              verticalAlign: "middle",
              formatter: function () {
                if (this.point.index === 0) {
                  if (this.series.name == "P2") {
                    return "P2";
                  } else if (this.series.name == "P1") {
                    return "P1";
                  }
                } else {
                  return "";
                }
              },
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
          formatter: function () {
            return this.points[0]?.point.firstMean !== undefined &&
              this.points[1]?.point.secondMean !== undefined
              ? `<div class="tooltip-vehicle-health">
                  <div class="flex-column ">
                   <div class="flex-column-border">&nbsp &nbsp Miles(X) &nbsp &nbsp</div>
                   <div class="flex-column-border">&nbsp</div>
                   <div class="flex-column-border"> &nbsp P1 </div>
                   <div class="flex-column-border"> &nbsp P2 </div>
                   <div class="flex-column-border"> &nbsp Difference </div>
                  </div>
                  <div class="flex-column">
                   <div class="second-column">&nbsp ${
                     graphPointName === "green"
                       ? "RUL(Y)"
                       : this.points[0].point.y1AxisLabel
                   } &nbsp</div>
                   <div class="flex">
                    <div class="flex-column">
                    <div class="flex-column-border">&nbsp Mean</div>
                    <div class="flex-column-border"> &nbsp ${
                      graphPointName === "green"
                        ? this.points[2].point.firstMean
                        : this.points[0].point.firstMean
                    } &nbsp </div>
                    <div class="flex-column-border"> &nbsp ${
                      graphPointName === "green"
                        ? this.points[3].point.secondMean
                        : this.points[1].point.secondMean
                    } &nbsp </div>
                    <div> &nbsp ${
                      graphPointName === "green"
                        ? Math.abs(
                            this.points[2].point.firstMean -
                              this.points[3].point.secondMean
                          )
                        : Math.abs(
                            this.points[0].point.firstMean -
                              this.points[1].point.secondMean
                          ).toFixed(2)
                    } &nbsp </div>
                    </div>
                    <div class="flex-column">
                    <div class="flex-column-border">&nbsp Min</div>
                    <div class="flex-column-border"> &nbsp ${
                      graphPointName === "green"
                        ? this.points[2].point.firstMin
                        : this.points[0].point.firstMin
                    } &nbsp
                  </div>
                  <div class="flex-column-border">
                   &nbsp ${
                     graphPointName === "green"
                       ? this.points[3].point.secondMin
                       : this.points[1].point.secondMin
                   } &nbsp
                  </div>
                  <div>
                   &nbsp ${
                     graphPointName === "green"
                       ? Math.abs(
                           this.points[2].point.firstMin -
                             this.points[3].point.secondMin
                         )
                       : Math.abs(
                           this.points[0].point.firstMin -
                             this.points[1].point.secondMin
                         ).toFixed(2)
                   } &nbsp
                  </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Max &nbsp</div>
                  <div class="flex-column-border">
                   &nbsp ${
                     graphPointName === "green"
                       ? this.points[2].point.firstMax
                       : this.points[0].point.firstMax
                   } &nbsp
                  </div>
                  <div class="flex-column-border">
                  &nbsp ${
                    graphPointName === "green"
                      ? this.points[3].point.secondMax
                      : this.points[1].point.secondMax
                  } &nbsp
                  </div>
                  <div>
                   &nbsp ${
                     graphPointName === "green"
                       ? Math.abs(
                           this.points[2].point.firstMax -
                             this.points[3].point.secondMax
                         )
                       : Math.abs(
                           this.points[0].point.firstMax -
                             this.points[1].point.secondMax
                         ).toFixed(2)
                   } &nbsp
                  </div>
                  </div>
                  </div>
                </div>`
              : this.points[0]?.point.firstMean !== undefined
              ? `<div class="tooltip-vehicle-health">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp &nbsp Miles(X) &nbsp &nbsp</div>
                  <div class="flex-column-border">&nbsp</div>
                  <div class="flex-column-border">&nbsp P1 </div>
                  </div>
                  <div class="flex-column">
                  <div class="second-column">&nbsp ${
                    graphPointName === "green"
                      ? "RUL(Y)"
                      : this.points[0].point.y1AxisLabel
                  } &nbsp</div>
                  <div class="flex">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp Mean  &nbsp</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.firstMean
                      : this.points[0].point.firstMean
                  } &nbsp </div>
                  </div>
                  <div class="flex-column">
                  <div  class="flex-column-border"> &nbsp Min &nbsp</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.firstMin
                      : this.points[0].point.firstMin
                  } &nbsp
                  </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Max &nbsp</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.firstMax
                      : this.points[0].point.firstMax
                  } &nbsp </div>
                  </div>
                  </div>
                </div>`
              : this.points[0]?.point.secondMean !== undefined
              ? `<div class="tooltip-vehicle-health">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp &nbsp Miles(X) &nbsp &nbsp</div>
                  <div class="flex-column-border">&nbsp</div>
                  <div class="flex-column-border"> &nbsp P2 </div>
                  </div>
                  <div class="flex-column">
                  <div class="second-column">&nbsp ${
                    graphPointName === "green"
                      ? "RUL(Y)"
                      : this.points[0].point.y1AxisLabel
                  } &nbsp</div>
                  <div class="flex">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp Mean</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.secondMean
                      : this.points[0].point.secondMean
                  } &nbsp </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Min</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.secondMin
                      : this.points[0].point.secondMin
                  } &nbsp
                  </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Max &nbsp</div>
                  <div> &nbsp ${
                    graphPointName === "green"
                      ? this.points[1].point.secondMax
                      : this.points[0].point.secondMax
                  } &nbsp </div>
                  </div>
                  </div>
                </div>`
              : this.points[1]?.point.secondMean !== undefined
              ? `<div class="tooltip-vehicle-health">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp &nbsp Miles(X) &nbsp &nbsp</div>
                  <div class="flex-column-border">&nbsp</div>
                  <div> &nbsp P2 </div>
                  </div>
                  <div class="flex-column">
                  <div class="second-column">&nbsp ${
                    graphPointName === "green"
                      ? "RUL(Y)"
                      : this.points[0].point.y1AxisLabel
                  } &nbsp</div>
                  <div class="flex">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp Mean</div>
                  <div> &nbsp ${this.points[1].point.secondMean} &nbsp </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Min</div>
                  <div> &nbsp ${this.points[1].point.secondMin} &nbsp
                  </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Max &nbsp</div>
                  <div> &nbsp ${this.points[1].point.secondMax} &nbsp </div>
                  </div>
                  </div>
                </div>`
              : this.points[1]?.point.mean !== undefined &&
                `<div class="tooltip-vehicle-health">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp &nbsp Miles(X) &nbsp &nbsp</div>
                  <div class="flex-column-border">&nbsp</div>
                  <div> &nbsp Difference </div>
                  </div>
                  <div class="flex-column">
                  <div class="second-column">&nbsp ${
                    graphPointName === "green"
                      ? "RUL(Y)"
                      : this.points[0].point.y1AxisLabel
                  } &nbsp</div>
                  <div class="flex">
                  <div class="flex-column">
                  <div class="flex-column-border">&nbsp Mean &nbsp</div>
                  <div> &nbsp ${Math.abs(
                    graphPointName === "green"
                      ? this.points[1].point.mean !== undefined
                        ? this.points[1].point.mean
                        : "--"
                      : this.points[0].point.mean !== undefined
                      ? this.points[0].point.mean
                      : "--"
                  )}
                     &nbsp </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Min &nbsp</div>
                  <div> &nbsp ${Math.abs(
                    graphPointName === "green"
                      ? this.points[1].point.min !== undefined
                        ? this.points[1].point.min
                        : "--"
                      : this.points[0].point.min !== undefined
                      ? this.points[0].point.min
                      : "--"
                  )} &nbsp
                  </div>
                  </div>
                  <div class="flex-column">
                  <div class="flex-column-border"> &nbsp Max &nbsp</div>
                  <div> &nbsp ${Math.abs(
                    graphPointName === "green"
                      ? this.points[1].point.max !== undefined
                        ? this.points[1].point.max
                        : "--"
                      : this.points[0].point.max !== undefined
                      ? this.points[0].point.max
                      : "--"
                  )} &nbsp </div>
                  </div>
                  </div>
                  </div>`;
          },
          useHTML: true,
          shared: true,
        },

        series: [
          P1data,
          P2data,
          P3data,
          P4data,
          P1colordata,
          P2colordata,
          P3colordata,
          P4colordata,
          P5data,
          P5colordata,
        ],
      },
      function (chart) {
        // on complete
        const data1Len = chart.series?.[0]?.data[0]?.y || 0;
        const data2Len = chart.series?.[1]?.data[0]?.y || 0;
        if (
          (data1Len === undefined || data1Len === 0) &&
          (data2Len === undefined || data2Len === 0)
        ) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 335, 180)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <>
      <figure className="highcharts-figure">
        <div id={id}></div>
        <div className="graph-name"></div>
      </figure>
    </>
  );
};
