export const prepareIshikawaData = (response) => {
  const childArray = [];
  console.log(" response.ishikawaDiagramDtoList", response.ishikawaDiagramDtoList)

  response.ishikawaDiagramDtoList.forEach((element) => {
    const index = childArray.findIndex(
      (value) => value.name === element.primaryCause
    );
    if (index === -1) {
      childArray.push({ name: element.primaryCause });
    }
  });


  childArray.forEach((element) => {
    const primaryList = response.ishikawaDiagramDtoList.filter(
      (value) => value.primaryCause === element.name
    );
    const secondaryList = [];
    primaryList.forEach((primaryElement) => {
      const index = secondaryList.findIndex(
        (value) => value.name === primaryElement.secondaryCause
      );
      if (index === -1) {
        secondaryList.push({ name: primaryElement.secondaryCause ,className: "custom-class"},);
      }
    });

    secondaryList.forEach((secondaryItem) => {
      const secondaryCauses = primaryList.filter(
        (secondaryElement) =>
          secondaryElement.secondaryCause === secondaryItem.name
      );

     

      const tertiaryList = [];
      secondaryCauses.forEach((ele) => {
        const index = ele.tertiaryCause
          ? tertiaryList.includes(ele.tertiaryCause)
          : true;
        if (!index) {
          // tertiaryList.push({name: ele.tertiaryCause, children: [{color: ele.potentialRootCause.toLowerCase()}]})
          tertiaryList.push({ name: ele.tertiaryCause });
        }
        
      });

      


      secondaryItem.children = tertiaryList;
      

   
    });

     console.log("secondaryList",secondaryList)
    

    element.children = secondaryList;

    


  });

  


  const probStatement = response.problem.split("-");


  console.log(childArray,"rrrr")


  return {
    name: probStatement.length > 1 ? probStatement[1] : probStatement[0],
    children: childArray,
  };
};

export const getPotentialRootCauseData = (response) => {
  const possibleCauses = response.ishikawaDiagramDtoList.filter(
    (elem) => elem.potentialRootCause.toLowerCase() !== "no"
  );
  return possibleCauses || [];
};
