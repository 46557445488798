import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../Layout/TabLayout";
import "./index.scss";
// import { BrowserRouter as Router, Route } from "react-router-dom";
// import { VehicleHealthCheckRoutes } from "../../utils/constants";

const VehicleHealthCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === "/vehicle-health") {
      navigate("/vehicle-health/diagnostics", { replace: true });
    }
  }, [location.pathname]);

  const navLink = [
    { label: "Diagnostics", to: "/vehicle-health/diagnostics" },
    { label: "Self Test", to: "/vehicle-health/self-test" },
   // { label: "Digital Twin", to: "/vehicle-health/poc-digital-twin" },
    { label: "Digital Twin", to: "/vehicle-health/digital-twin"},
  ];
  return (
    <div className="full-width ">
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default VehicleHealthCheck;
