import React from "react";
import compare_vin from "../assets/compare-with-vin-icon.svg";
import plusIcon from "../assets/plus-icon.svg";
import viewEye from "../assets/view-eye.svg";
import { Outlet, useLocation, useNavigate } from "react-router";
import "../SavedFilters/index.scss";
import SearchIcon from "../../AdminDashboard/assets/search.svg";
import { useState } from "react";
import Select from "../../../components/common/Select/Select";
import FilterCard from "./FilterCard";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import { useEffect } from "react";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import ModalPopup from "../../../components/common/Modal/ModalPopup";
import { useDispatch } from "react-redux";
import {
  addFirstFilter,
  addSecondFilter,
  addVinFilter,
  dropDownFilterSave,
} from "../../../store/actions/fetchActions";
import close from "../../../assets/close.svg";

const SavedFiltersDashboard = () => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const interval = ["All", "Prognostics", "Diagnostics"];
  const [defaultInterval, setDefaultInterval] = useState("All");
  const [data, setData] = useState([]);
  const [checkedCards, setCheckedCards] = useState([]);
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [comparePopup, setComparePopup] = useState(false);
  const [errorModel, setErrorModel] = useState("");
  const [compareVinNumber, setCompareVinNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filterCardname, setFilterCardname] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [filterErrModel, setFilterErrModel] = useState("");
  const [validationMsg, setValidationMsg] = useState("");
  const [checkedCardsValidation, setCheckedCardsValidation] = useState([]);

  useEffect(() => {
    getData();
  }, [defaultInterval]);

  useEffect(() => {
    if (userInput.length >= 3) {
      getFilteredData();
    } else if (userInput.length === 0) {
      getData();
    }
  }, [userInput]);

  const getData = () => {
    const url = `${VehiclePopulationDashBoard.saved_filters}`;
    let params = {
      filterType: defaultInterval,
      searchValue: userInput,
      make: "Ford",
      page: 0,
      size: 50,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data?.content || []);
          setApiError(false);
        }
      })
      .catch((err) => {});
  };

  const getFilteredData = () => {
    const url = `${VehiclePopulationDashBoard.search_filter}`;
    let params = {
      make: "Ford",
      searchValue: userInput,
      filterType: "All",
      page: 0,
      size: 50,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data?.content || []);
          setApiError(false);
        }
        if (response.status === 401) {
          // setErrorMsg(response.data?.details || "You are not authorized.");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiError(true);
        } else {
          setApiError(true);
        }
      });
  };

  const handleSearch = (e) => {
    setUserInput(e.target.value);
  };

  const onEnter = (e) => {
    setUserInput(e.target.value);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
  };

  const HandleClick = () => {
    const FilterSelectedValues = localStorage.getItem("isFilterSaved");
    if (FilterSelectedValues !== "true" && FilterSelectedValues !== null) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigate("/vehicle-population/FormLayout", {
        state: { path: location.pathname },
      });
      localStorage.removeItem("filterSelectedValues");
    }
  };

  const handleCheckedCard = (e) => {
    if (e.target.checked) {
      setCheckedCardsValidation({
        id:
          checkedCards.id !== undefined || null
            ? [...checkedCards.id, e.target.value]
            : [e.target.value],
      });
    } else {
      setCheckedCardsValidation({
        id: checkedCards?.id?.filter((item) => item !== e.target.value),
      });
    }

    if (
      checkedCards.id?.length === undefined ||
      checkedCards.id?.length === 0 ||
      checkedCards.id?.length === 1
    ) {
      if (e.target.checked) {
        setCheckedCards({
          id:
            checkedCards.id !== undefined || null
              ? [...checkedCards.id, e.target.value]
              : [e.target.value],
        });
      } else {
        setCheckedCards({
          id: checkedCards?.id?.filter((item) => item !== e.target.value),
        });
        setValidationMsg("");
      }
    } else {
      if (checkedCardsValidation.id?.length >= 2) {
        setValidationMsg("Maximum two cards are allowed to compare");
      } else {
        setValidationMsg("");
      }
      setCheckedCards({
        id: checkedCards?.id?.filter((item) => item !== e.target.value),
      });
    }
  };

  const handleCompareVin = () => {
    if (checkedCards?.id?.length === 1) {
      setComparePopup(true);
    } else {
      setComparePopup(false);
    }
  };

  const handleCompareVinNumber = (value) => {
    setCompareVinNumber(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setErrorModel("VIN number should not contain special characters");
    } else if (value.includes(" ")) {
      setErrorModel("VIN number should not contain space");
    } else {
      setErrorModel("");
    }
  };

  const ModelCloseButton = () => {
    setComparePopup(false);
  };

  const submitVinCompare = () => {
    dispatch(dropDownFilterSave(true));
    const selectedCard = data.find((item) => item.id === checkedCards?.id[0]);
    dispatch(addFirstFilter(selectedCard));
    dispatch(addSecondFilter(null));
    dispatch(addVinFilter(compareVinNumber));
    navigate("/vp-saved-filter/comparision/GeneratedDashboard/diagnostics", {
      replace: false,
    });
  };
  const handleCompareSavedCards = () => {
    if (checkedCards?.id?.length === 2) {
      const selectedCard_1 = data.find(
        (item) => item.id === checkedCards?.id[0]
      );
      const selectedCard_2 = data.find(
        (item) => item.id === checkedCards?.id[1]
      );
      dispatch(addFirstFilter(selectedCard_1));
      dispatch(addSecondFilter(selectedCard_2));
      dispatch(dropDownFilterSave(true));
      dispatch(addVinFilter(null));
      navigate("/vp-saved-filter/comparision/GeneratedDashboard/diagnostics", {
        replace: false,
      });
    } else if (checkedCards?.id?.length === 1) {
      const selectedCard = data.find((item) => item.id === checkedCards?.id[0]);
      localStorage.setItem("selectedData", JSON.stringify(selectedCard));
      const selectedDiagonosticsData = {
        fromMiles: 1,
        toMiles: 200000,
        type: "by_miles",
      };
      localStorage.setItem(
        "selectedDiagonosticsData",
        JSON.stringify(selectedDiagonosticsData)
      );
      localStorage.setItem("isFilterSaved", true);
      navigate("/vehicle-population/diagnostic");
    }
  };

  const modalStyle = {
    position: "fixed",
    background: "#000000aa",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1111,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const modalContentStyle = {
    textAlign: "left",
    position: "fixed",
    top: "50%",
    left: "50%",
    width: 400,
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    display: "",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11111,
  };

  const closeModelPopup = () => {
    setShowModal(false);
    setSaveModal(false);
  };

  const handleSaveFilter = () => {
    setFilterCardname("");
    setShowModal(false);
    setSaveModal(true);
  };

  const handleRmFilter = () => {
    setShowModal(false);
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  const handleInputCardnameChange = (e) => {
    setFilterCardname(e.target.value);
    validateInput(e.target.name, e.target.value);
  };

  const validateInput = (name, value) => {
    let errors = "";
    switch (name) {
      case "filterCardname":
        errors =
          value.length === 0
            ? "Filter name should not be empty"
            : value.length >= 30
            ? "Filter name should not be more than 30 characters"
            : "";
        break;
      default:
        break;
    }
    setFilterErrModel(errors);
  };

  const handleSaveCancel = () => {
    setSaveModal(false);
    setFilterErrModel("");
  };

  const saveToFilterCard = async () => {
    let params = {};
    let selectedData = JSON.parse(localStorage.getItem("selectedData"));
    let selectedDiagonosticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    let rul = localStorage.getItem("rul");
    let lowerLimitDataLS = localStorage.getItem("lowerLimitData");
    let upperLimitDataLS = localStorage.getItem("upperLimitData");
    let lowerLimitDataFromLS = localStorage
      .getItem("lowerLimitData")
      ?.slice(1, lowerLimitDataLS.length - 1);
    let upperLimitDataFromLS = localStorage
      .getItem("upperLimitData")
      ?.slice(1, upperLimitDataLS.length - 1);
    if (rul !== null) {
      switch (rul) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitDataFromLS),
            toRulMiles: parseInt(upperLimitDataFromLS),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitDataFromLS),
            toRulEngineRunTime: parseInt(upperLimitDataFromLS),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitDataFromLS),
            toRulHours: parseInt(upperLimitDataFromLS),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitDataFromLS),
            toRulKeyStarts: parseInt(upperLimitDataFromLS),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitDataFromLS),
            toRulMiles: parseInt(upperLimitDataFromLS),
            rul: "by_miles",
          };
        }
      }
    }

    if (filterCardname !== "") {
      const url = `${VehiclePopulationDashBoard.saved_filters}`;
      let filterType = rul ? "Prognostics" : "Diagnostics";
      let filterData = {
        ...selectedData, //for general data
        ...selectedDiagonosticsData, // for diagnostics data
        ...params, //for rul data
      };
      let data = {
        make: "Ford",
        filterName: filterCardname,
        filterType: filterType,
        ...filterData,
      };
      return mainAxiosInstance
        .post(url, data)
        .then((response) => {
          if (response.status === 200) {
            removeFilterAndRedirect();
            setSaveModal(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setFilterErrModel("You are not authorized");
          } else {
            setFilterErrModel("Something went wrong ");
          }
        });
    }
  };

  const removeFilterAndRedirect = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  return (
    <div className="saved-filter-container">
      <div className="top-button-container">
        <div className="left-side-content">
          <div className="search_container saved-filters">
            <div className="dropdown_content">
              <Select
                options={interval}
                defaultSelected={defaultInterval}
                handleOptionClick={handleIntervalOptionClick}
              />
            </div>
            <div className="search-field-container">
              <img src={SearchIcon} alt="search" className="search_image" />
              <input
                type="text"
                className="search_field"
                placeholder="Search for attribute"
                value={userInput}
                onChange={handleSearch}
                onKeyPress={onEnter}
              />
            </div>
          </div>
        </div>
        <div className="top-button-container-icons">
          <p className="plus-icon-button" onClick={HandleClick}>
            <img
              className=""
              src={plusIcon}
              alt="wa"
              onClick={HandleClick}
              style={{ cursor: "pointer" }}
            />
          </p>
          <p
            className={
              checkedCards?.id?.length === 1
                ? "compare-with-vin-button"
                : "compare-with-vin-button disabled"
            }
            onClick={handleCompareVin}
          >
            Compare with VIN
            <img className="" src={compare_vin} alt="wa" />
          </p>
          <p
            className={
              checkedCards?.id?.length === 1 || checkedCards?.id?.length === 2
                ? "compare-button"
                : "compare-button disabled"
            }
            onClick={handleCompareSavedCards}
          >
            {checkedCards?.id?.length === 1 ? "View" : "Compare"}
            {checkedCards?.id?.length === 1 ? (
              <img src={viewEye} alt="wa" style={{ paddingLeft: "3px" }} />
            ) : (
              <img src={compare_vin} alt="wa" />
            )}
          </p>
        </div>
      </div>
      {comparePopup && checkedCards?.id?.length === 1 ? (
        <>
          <ModalPopup
            firstParagraph={"Enter VIN number here"}
            firstButton="Cancel"
            secondButton="Compare"
            onValueChange={handleCompareVinNumber}
            closeModal={ModelCloseButton}
            errorModel={errorModel}
            onClick={submitVinCompare}
            inputValue={"DH34ASD7SDFF84742"}
            placeholder={"Enter the VIN number"}
            buttonClassName={
              compareVinNumber.length > 0 && errorModel.length === 0
                ? "btn-primary-blue"
                : "btn-primary-blue disabled"
            }
            disableSecondButton={
              compareVinNumber.length > 0 && errorModel.length === 0
                ? false
                : true
            }
          />
        </>
      ) : (
        <></>
      )}
      {!apiError && (
        <div className="saved-filter-card-wrapper">
          {data.slice(0, 15).map((obj) => {
            return (
              <FilterCard
                data={obj}
                handleCheckedCard={handleCheckedCard}
                checkedCards={checkedCards}
                getData={getData}
                validationMsg={validationMsg}
                checkedCardsValidation={checkedCardsValidation}
              />
            );
          })}
        </div>
      )}
      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <div className="discard-card-modal-close" onClick={closeModelPopup}>
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>
              Do you want to discard the changes
            </p>
            <div className="discardBtns">
              <button onClick={handleSaveFilter} className="btn-secondary-blue">
                No
              </button>
              <button
                onClick={handleRmFilter}
                style={{ width: 80, marginLeft: "1em" }}
                className="btn-primary-blue"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {saveModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <span className="error-text">{filterErrModel}</span>
            <div className="discard-card-modal-close" onClick={closeModelPopup}>
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>Save this filter as</p>
            <input
              name="filterCardname"
              type="text"
              className="saveInput"
              value={filterCardname}
              onChange={handleInputCardnameChange}
              maxLength={30}
            />
            <div className="modBtns">
              <button onClick={handleSaveCancel} className="btn-secondary-blue">
                Cancel
              </button>
              <button
                onClick={saveToFilterCard}
                className={
                  filterCardname.length >= 1
                    ? "btn-primary-blue"
                    : "btn-primary-blue disabled"
                }
                style={{ width: 100, marginLeft: "1em" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {apiError && (
        <>
          <Feedback />
        </>
      )}

      {/* <Outlet /> */}
    </div>
  );
};
export default SavedFiltersDashboard;
