import React, { useEffect, useState } from "react";
import Select from "../../components/common/Select/Select";
import "./index.scss";
import intervalPreIcon from "../../assets/interval.png";
import Filter from "../../components/Filter/Filter";
import { Checkbox } from "../../components/common/Checkbox/Checkbox";
import { mainAxiosInstance } from "../../services/service";
import { ServiceConfig } from "../../services/service-constants";
import Modal from "../../components/common/Modal/Modal";
import DTCDetails from "../../components/VehicleHealthCheck/Diagnostics/DTCDetails";
import LeftIcon from "../../assets/left-icon.svg";
import RightIcon from "../../assets/right-icon.svg";
import Analyticgraph from "../../components/common/Analyticgraph";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addRootCausePayload } from "../../store/rootCauseAnalysis/actions";
import FeedBack from "../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../components/common/ErrorComponents/LottieLoad";
import { isEmpty } from "lodash";
import clockIcon from "../../assets/clockIcon.svg";
import Breadcrumbs from "../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../utils/constants";

const FaultTrendAnalytics = () => {
  const dispatch = useDispatch();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const navigate = useNavigate();
  const metrics = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const [defaultMetric, setDefaultMetric] = useState("Miles");
  const [dtcValues, setDTCValues] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedDTC, setSelectedDTC] = useState({});
  const [minimumInputValue, setMinimumInputValue] = useState(0);
  const [maximumInputValue, setMaximumInputValue] = useState(10000);
  const [showModal, setShowModal] = useState(false);
  const [maxPageNum, setMaxPageNum] = useState(0);
  const [apiError, setAPiError] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [loaderChart, setLoaderChart] = useState();
  const [isExpanded, setExpanded] = useState(true);

  const filterOptions = [
    { displayname: "Engine", id: "engine", isChecked: true },
    { displayname: "Transmission", id: "transmission", isChecked: true },
    {
      displayname: "BCM",
      id: "bcm",
      isChecked: true,
    },
    {
      displayname: "Battery Management Controller",
      id: "tirePressure",
      isChecked: true,
    },
    { displayname: "ABS Controller", id: "engineTorque", isChecked: true },
    {
      displayname: "Airbag",
      id: "transmissionOilTemp",
      isChecked: true,
    },
    {
      displayname: "4WD",
      id: "engineOilTemperature",
      isChecked: true,
    },
  ];
  const [filterData, setFilterData] = useState([...filterOptions]);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [dtcNumbers, setDTCNumbers] = useState([]);

  const [selectedDTCName, setSelectedDTCName] = useState();

  const [showRULModal, setShowRULModal] = useState({
    showModal: false,
    RULFor: null,
  });
  const [RULData, setRULData] = useState({});

  const handleIntervalOptionClick = (val) => {
    setDefaultMetric(val);
  };

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    sortDTC(dtcValues, newFilterData);
    setSelectAllvalue(newFilterData);
  };

  const sortDTC = (dtcValues, newFilterData) => {
    let newDTCList = dtcValues;
    newFilterData.map((el) => {
      if (!el.isChecked) {
        newDTCList = newDTCList.filter(
          (ele) => ele.subSystem !== el.displayname
        );
      }
    });
    getDTCList(newDTCList);
  };

  const selectDtc = (val) => {
    setSelectedDTCName(val);
    const dtc = dtcValues.filter((el) => el.dtcCode === val);
    setSelectedDTC(dtc?.[0] || []);
  };

  const getDTCList = (values) => {
    const dtcList = values.map((el) => el.dtcCode);
    setDTCNumbers(dtcList || []);
    const dtcNumberSelected = dtcList?.[0] || "";
    setSelectedDTCName(dtcNumberSelected);
    const dtcSelection = values.find((el) => el.dtcCode === dtcNumberSelected);
    setSelectedDTC(dtcSelection || {});
  };

  const getDTC = (val) => {
    let url = `${ServiceConfig.fault_trend_analytics}?page=${pageNumber}&size=10`;
    let params = {};

    switch (defaultMetric) {
      case "Miles": {
        //  url = `${ServiceConfig.fault_trend_analytics}miles`;
        params = {
          queryType: "by_miles",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulMiles: minimumInputValue,
          toRulMiles: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Hours": {
        url = `${ServiceConfig.fault_trend_analytics}?page=${pageNumber}&size=10`;
        params = {
          queryType: "by_hours",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulHours: minimumInputValue,
          toRulHours: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Engine Run Time": {
        url = `${ServiceConfig.fault_trend_analytics}?page=${pageNumber}&size=10`;
        params = {
          queryType: "by_run_time",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulEngineRuntime: minimumInputValue,
          toRulEngineRuntime: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Key starts": {
        url = `${ServiceConfig.fault_trend_analytics}?page=${pageNumber}&size=10`;
        params = {
          queryType: "by_key_starts",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulKeyStarts: minimumInputValue,
          toRulKeyStarts: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      // default: {
      //   //   url = `${ServiceConfig.fault_trend_analytics}miles`;
      //   params = {
      //     queryType: "by_miles",
      //     vinNumber: val.vin_Number,
      //     organizationId: 1,
      //     fromRulMiles: minimumInputValue,
      //     toRulMiles: maximumInputValue,
      //     page: pageNumber,
      //     size: pageSize,
      //   };
      //   break;
      // }
    }

    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setDTCValues(response.data?.content || []);
          getDTCList(response.data?.content);
          sortDTC(response.data?.content || [], filterData);
          setMaxPageNum(response.data.totalPages);
          setAPiError(false);
          setErrorMsg("");
          setLoaderChart(response.data?.content);
          return response?.data;
        }
        if (response.status === 401) {
          setErrorMsg(response.data.detail || "You are not authorized");
          setNotAuthorized(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNotAuthorized(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setAPiError(true);
          setErrorMsg(err.response.data.details || "No records Found");
        }
      });
  };

  const showDetails = (val) => {
    setShowModal(true);
  };
  const getRULData = () => {
    let xAxisrul = [];
    let yAxisrul = [];
    let Axisrul = [];
    RULData.fTARulGraphs.map((rul, index) => {
      xAxisrul.push(rul.xAxisData);
      yAxisrul.push(rul.yAxisData);
      Axisrul.push([rul.xAxisData, rul.yAxisData]);
    });
    yAxisrul.push(RULData.fTARulGraphsThresholds.thresholdStart[1]);
    yAxisrul.push(RULData.fTARulGraphsThresholds.thresholdEnd[1]);
    xAxisrul.push(RULData.fTARulGraphsThresholds.thresholdStart[0]);
    xAxisrul.push(RULData.fTARulGraphsThresholds.thresholdEnd[0]);
    Axisrul.push([
      RULData.fTARulGraphsThresholds.thresholdStart[0],
      RULData.fTARulGraphsThresholds.thresholdStart[1],
    ]);
    Axisrul.push([
      RULData.fTARulGraphsThresholds.thresholdEnd[0],
      RULData.fTARulGraphsThresholds.thresholdEnd[1],
    ]);
    const data = {
      seriesdata: yAxisrul,
      categories: xAxisrul,
      data: Axisrul,
      units: RULData.fTARulGraphsThresholds.rulValue,
      xAxisLable: RULData?.fTARulGraphs[0].xAxisName,
      yAxisLable: RULData?.fTARulGraphs[0].yAxisName,
    };

    return data;
  };

  const showRULDetails = (graphName) => {
    let url = `${ServiceConfig.fault_trend_analytics_graph}rul_graph`;

    const data = {
      vinNumber: selectedDTC.vinNumber,
      organizationId: selectedDTC.organizationId,
      statsDate: selectedDTC.statsDate,
      dtcCode: selectedDTC.dtcCode,
      graphName: graphName,
    };

    //added dummy data for api
    // const data = {
    //   vinNumber: "DH34ASD7SDFF84742",
    //   organizationId: 1,
    //   statsDate: "2022-06-02",
    //   dtcCode: "P0100",
    //   graphName: "ERTF",
    // };

    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        let resData = response.data;

        setRULData({
          fTARulGraphs: resData.faultTrendAnalyticsRulGraphs,
          fTARulGraphsThresholds:
            resData.faultTrendAnalyticsRulGraphsThresholds,
        });

        setShowRULModal({ showModal: true, RULFor: null });
      })
      .catch((er) => {
        throw er;
      });
  };

  const previousHandler = () => {
    const pageNum = pageNumber - 1;
    setPageNumber(pageNum < 0 ? 0 : pageNum);
  };

  const nextHandler = () => {
    const pageNum = pageNumber + 1;
    setPageNumber(pageNum > maxPageNum - 1 ? maxPageNum - 1 : pageNum);
  };

  useEffect(() => {
    getDTC({
      vin_Number: vin_Number,
    });
  }, [pageNumber]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
    if (val) {
      getDTCList(dtcValues);
    } else {
      let newDTCList = dtcValues;
      filterData.map((el) => {
        newDTCList = newDTCList.filter(
          (ele) => ele.subSystem !== el.displayname
        );

        getDTCList(newDTCList);
      });
    }
  };

  const goToRootCause = () => {
    navigate("./root-cause-analysis");
  };

  useEffect(() => {
    const state = {
      vin: selectedDTC.vinNumber,
      oId: selectedDTC.organizationId,
      dcode: selectedDTC.dtcCode,
      stats: selectedDTC.statsDate,
    };
    dispatch(addRootCausePayload({ ...state }));
  }, [selectedDTC]);

  const renderDTCSpecificResponse = () => {
    const heading =
      selectedDTC?.dtcCode && selectedDTC?.name
        ? `${selectedDTC?.dtcCode} - ${selectedDTC?.name}`
        : "";
    const description = selectedDTC?.description?.split(".") || [];
    const subSystems = selectedDTC?.subSystem?.split(",") || [];
    return (
      <>
        <div className="selected-dtc-name-number">
          <span className="dtc-name-number">{heading}</span>
          <button
            className="btn btn-secondary-blue fault-trend-show-details"
            onClick={() => showDetails()}
          >
            Show Details
          </button>
        </div>
        {showModal && (
          <Modal
            type={"large"}
            title={"DTC Details"}
            wrapperClass="main-diagnostics-modal"
            titlePosition={"center"}
            isError={false}
            handleClose={() => setShowModal(false)}
          >
            <DTCDetails
              dtcValue={selectedDTC.dtcCode}
              statsDate={selectedDTC.statsDate}
              handleClose={() => setShowModal(false)}
              handleClick={() => goToRootCause()}
              buttonText="Root Cause Analysis"
            />
          </Modal>
        )}
        <div className="fault-description-wrapper">
          <div className="heading">Description:</div>
          <ul className="pointers-wrapper">
            {description.map((el) => (
              <li className="pointers">{el}</li>
            ))}
          </ul>
        </div>
        <div className="fault-sub-system-wrapper sub-systems">
          <div className="heading">Sub Systems:</div>
          <ul className="pointers-wrapper">
            {subSystems.map((el) => (
              <li className="pointers">{el}</li>
            ))}
          </ul>
        </div>
        <div className="fault-sub-system-wrapper remaining-useful-life">
          <div className="heading">Remaining useful life:</div>
          <ul className="pointers-wrapper">
            <li className="pointers">
              <div className="rul-items">
                <span className="item-name">Engine run time to failure:</span>
                <span className="item-value">
                  {selectedDTC.rulEngineRunTime} min
                </span>
                <span
                  className="btn btn-secondary-blue details-button"
                  onClick={() => showRULDetails("ERTF")}
                >
                  Details
                </span>
              </div>
            </li>
            <li className="pointers">
              <div className="rul-items">
                <span className="item-name">Distance to failure:</span>
                <span className="item-value">{selectedDTC.rulMiles} miles</span>
                <span
                  className="btn btn-secondary-blue details-button"
                  onClick={() => showRULDetails("DTF")}
                >
                  Details
                </span>
              </div>
            </li>
            <li className="pointers">
              <div className="rul-items">
                <span className="item-name">Ignition starts to failure:</span>
                <span className="item-value">{selectedDTC.rulKeyStarts}</span>
                <span
                  className="btn btn-secondary-blue details-button"
                  onClick={() => showRULDetails("KTF")}
                >
                  Details
                </span>
              </div>
            </li>
          </ul>
        </div>
        {showRULModal.showModal && (
          <Modal
            type={"medium"}
            title={""}
            titlePosition={"center"}
            isError={false}
            wrapperClass="fault-trend-modal"
            handleClose={() =>
              setShowRULModal({ ...showRULModal, showModal: false })
            }
          >
            <div className="analyticgraph--top">
              <span>
                VIN: <span>{selectedDTC.vinNumber}</span>
              </span>
              <span>
                Date: <span>{selectedDTC.statsDate}</span>
              </span>
              <span>
                Current ERT:
                <span>
                  {RULData.fTARulGraphsThresholds.thresholdStart[0]} min
                </span>
              </span>
            </div>
            <Analyticgraph
              code={selectedDTC.dtcCode}
              chartdata={getRULData()}
            />
            <div className="analyticgraph--bottom">MAF Failure Prognostics</div>
          </Modal>
        )}
      </>
    );
  };

  // if (notAuthorized) {
  //   return <NotAuthorized />;
  // }

  // if (apiError) {
  //   return <FeedBack />;
  // }

  if (!loaderChart & !apiError) {
    return <LottieLoad />;
  }

  return (
    <div className="page-wrapper fault-trend-container">
      <div
        className={` ${isExpanded ? "" : "expand"}`}
        style={{ position: "relative", right: "11px" }}
      >
        <Breadcrumbs routes={routes} />
      </div>

      <div className="flex page-content-header">
        <div className="page-options-container">
          <div className="fault-trend-select">
            <Select
              heading="Remaining Useful Life"
              headingIcon={clockIcon}
              options={metrics}
              defaultSelected={defaultMetric}
              handleOptionClick={handleIntervalOptionClick}
              // preIcon={intervalPreIcon}
            />
          </div>
          <input
            type="text"
            className="value-input fault-trend-input"
            placeholder="Enter Lower Limit"
            value={minimumInputValue}
            onChange={(e) => {
              setMinimumInputValue(e.target.value);
            }}
            maxLength={10}
          />
          <input
            type="text"
            className="value-input fault-trend-input"
            placeholder="Enter Upper Limit"
            value={maximumInputValue}
            onChange={(e) => {
              setMaximumInputValue(e.target.value);
            }}
            maxLength={10}
          />
          <input
            type="submit"
            className="btn btn-primary-blue get-dtc"
            value="Get DTC's"
            onClick={() =>
              getDTC({
                vin_Number: vin_Number,
              })
            }
          />
        </div>
        <div className="page-filter fault-trend-filter">
          <Filter data={AllSelect} selectAll={selectAll}>
            {filterData.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleFilterClick(el)}
                alignProp={{
                  borderTop: "0.5px solid #C4C4C4",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
                checkboxStyle={{ marginRight: "10px" }}
                isChecked={el.isChecked}
              />
            ))}
          </Filter>
        </div>
      </div>
      {!apiError ? (
        <div>
          {dtcNumbers.length > 0 && (
            <div className="predicted-dtc-wrapper">
              <div className="predicted-dtc-text">Predicted DTCs</div>
              <div className="predicted-dtc">
                <div
                  className={`left-arrow-wrapper ${
                    pageNumber === 0 ? "disabled" : ""
                  }`}
                  onClick={() => previousHandler()}
                >
                  <img
                    className="left-arrow"
                    src={LeftIcon}
                    alt="previous"
                  ></img>
                </div>
                <span className="low-rul">Low RUL</span>
                <div className="dtc-numbers-wrapper">
                  <ul className="dtc-numbers-ul">
                    {dtcNumbers.map((el) => (
                      <li
                        className={`dtc-numbers-li ${
                          selectedDTCName === el ? "active" : ""
                        }`}
                        onClick={() => selectDtc(el)}
                      >
                        {el}
                      </li>
                    ))}
                  </ul>
                </div>
                <span className="high-rul">High RUL</span>
                <div
                  className={`right-arrow-wrapper ${
                    pageNumber === maxPageNum - 1 ? "disabled" : ""
                  }`}
                  onClick={() => nextHandler()}
                >
                  <img className="right-arrow" src={RightIcon} alt="next"></img>
                </div>
              </div>
            </div>
          )}
          {dtcNumbers.length > 0 && renderDTCSpecificResponse()}
          {dtcNumbers.length == 0 && (
            <div>
              <FeedBack />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="page-wrapper">
            <FeedBack />
          </div>
        </>
      )}
    </div>
  );
};

export default FaultTrendAnalytics;
