import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  dropDownFilterSave,
  getIsMenuExpanded,
} from "../../store/actions/fetchActions";
import { addvin_Number } from "../../store/actions/fetchActions";
import HeaderLogo from "../Header/headerLogo";
//import { useEffect } from "react";
import downArrow from "../../assets/arrow-drop-down-black.svg";
import downArrowThin from "../../assets/arrow-drop-down-black-thin.svg";
import accessibilityIcon from "../../assets/accessibilityIcon.svg";

import collapse from "../../assets/collapse.svg";
import expand from "../../assets/expand.svg";
import VehiclePopulation from "../../../src/pages/VehiclePopulation/index";
import darkmode from "../../assets/darkmode.svg";
import { replace } from "lodash";
export const NavigationMenu = ({
  menuItmes,
  clickExpand,
  className,
  dashboardRoute,
  collapseClass,
  redirect,
  checkandRedirect,
}) => {
  const [isExpanded, setExpanded] = useState(true);
  const [isScrollEnable, setIsScrollEnable] = useState(true);
  const vinNumberFromRedux = useSelector(
    (state) => state.mainReducer.vin_Number
  );
  const NavigationType = localStorage.getItem("NavigationMenType");
  const showFilter = useSelector(
    (state) => state.mainReducer.dropDown_Filter_Save
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [dashboardDropdownMenu, setDashboardDropdownMenu] = useState(true);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [moreDashboard, setMoreDashboard] = useState(false);
  const [moreSavedFilter, setMoreSavedFilter] = useState(false);
  const onCollapseExpand = () => {
    const expandValue = !isExpanded;
    setExpanded(expandValue);
    clickExpand(expandValue);
    dispatch(getIsMenuExpanded(expandValue));
  };
  const navigate = useNavigate();

  /** To prevent scroll if cursor is on nav div */
  useEffect(() => {
    const handleWheel = (event) => {
      if (!isScrollEnable) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel, { passive: false });
    };
  }, [isScrollEnable]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (!isScrollEnable) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel, { passive: false });
    };
  }, [isScrollEnable]);

  useEffect(() => {
    if (location.pathname.includes("comparision")) {
      dispatch(dropDownFilterSave(true));
    } else {
      dispatch(dropDownFilterSave(false));
    }
  }, [location.pathname]);

  const handleClick = (event, page, path, title) => {
    event.preventDefault();
    checkandRedirect(page, path);
    // const allowed = checkandRedirect(page, path);
    // if (allowed) {
    //   history.push(path);
    // }
    if (title === "Dashboard") {
      setDashboardDropdownMenu(!dashboardDropdownMenu);
      setDropdownMenu(false);
      setMoreDashboard(false);
    } else if (title === "Saved Filters") {
      setDropdownMenu(!dropdownMenu);
      setDashboardDropdownMenu(false);
      setMoreSavedFilter(false);
    }
  };

  const handleMore = (title) => {
    if (title === "Dashboard") {
      setMoreDashboard(!moreDashboard);
    }
    if (title === "Saved Filters") {
      setMoreSavedFilter(!moreSavedFilter);
    }
  };
  useEffect(() => {
    if (location.pathname.includes("/vp-saved-filter/")) {
      setDropdownMenu(true);
      setDashboardDropdownMenu(false);
    } else if (location.pathname.includes("/vehicle-population/")) {
      setDashboardDropdownMenu(true);
      setDropdownMenu(false);
    }
  }, [location.pathname]);
  return (
    <>
      <div
        className={`nav-menu ${
          isExpanded ? `expand ${className}` : `collapse ${collapseClass}`
        }`}
        // onScroll={scrolling}
        onMouseEnter={() => setIsScrollEnable(false)}
        onMouseLeave={() => setIsScrollEnable(true)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <HeaderLogo isExpanded={isExpanded} redirectToDashborad={redirect} />
        </div>

        <div
          className={`nav-go-back-div ${
            isExpanded ? "nav-accessibility" : "nav-access-column"
          }`}
        >
          {/* <div
            className={`nav-go-back-div ${
              isExpanded ? "" : "nav-access-column"
            }`}
          >
            <img
              src={isExpanded ? darkmode : darkmode}
              alt="access"
              // onClick={() => onCollapseExpand()}
              style={{ marginRight: "5px" }}
            />
            <img
              src={isExpanded ? accessibilityIcon : accessibilityIcon}
              alt="access"
              // onClick={() => onCollapseExpand()}
            />
          </div> */}

          <img
            src={isExpanded ? collapse : expand}
            alt="access"
            onClick={() => onCollapseExpand()}
          />
        </div>
        <div
          className={` ${isExpanded ? "nav-items-icon" : "collapse-nav-icon"}`}
        >
          {NavigationType === "IndividualVehicle" ? (
            <NavLink
              to={"/dashboard"}
              className={({ isActive }) => (isActive ? "is-active" : "")}
            >
              <span className={`icon nav-icons hamburger-icon`}></span>
              {isExpanded && <span className="nav-items">Dashboard</span>}
            </NavLink>
          ) : (
            <></>
          )}
        </div>

        {NavigationType === "VehiclePopulation" ? (
          <>
            {menuItmes?.length &&
              menuItmes.map((item) => (
                <>
                  <div
                    className={` ${
                      isExpanded ? "nav-items-icon" : "collapse-nav-icon"
                    }`}
                  >
                    <NavLink
                      exact
                      key={item.title}
                      to={item.path}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "VehiclePopulation",
                          item.path,
                          item.title
                        )
                      }
                      className={({ isActive }) =>
                        isActive ? "is-active" : ""
                      }
                    >
                      <span
                        className={`icon nav-icons ${item.iconName}`}
                      ></span>
                      {isExpanded && (
                        <>
                          <span className="nav-items">{item.title}</span>
                          {item.title === "Dashboard" ? (
                            <span
                              className={
                                dashboardDropdownMenu
                                  ? "nav-items-arrow down"
                                  : "nav-items-arrow"
                              }
                            >
                              <img src={downArrow} alt="" />
                            </span>
                          ) : (
                            <span
                              className={
                                dropdownMenu && showFilter
                                  ? "nav-items-arrow down"
                                  : "nav-items-arrow"
                              }
                            >
                              <img src={downArrow} alt="" />
                            </span>
                          )}
                        </>
                      )}
                    </NavLink>
                  </div>
                  {item.title === "Dashboard" ? (
                    <div>
                      {dashboardDropdownMenu ? (
                        <div>
                          <div
                            className={
                              moreDashboard
                                ? "nav-child-full"
                                : "nav-child-menu"
                            }
                          >
                            {item.children?.map((child) => {
                              return (
                                <div
                                  className={` ${
                                    isExpanded
                                      ? "nav-child"
                                      : "collapse-nav-child"
                                  }`}
                                >
                                  <NavLink
                                    key={child.title}
                                    to={child.path}
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        "VehiclePopulation",
                                        child.path
                                      )
                                    }
                                    className={({ isActive }) =>
                                      isActive ? "is-active" : ""
                                    }
                                  >
                                    <span
                                      className={`icon nav-icons ${child.iconName}`}
                                    ></span>
                                    {isExpanded && (
                                      <span className="nav-items">
                                        {child.title}
                                      </span>
                                    )}
                                  </NavLink>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className={
                              isExpanded
                                ? "more-nav-menu"
                                : "more-nav-menu collapse"
                            }
                            onClick={() => handleMore(item.title)}
                          >
                            <img
                              src={downArrowThin}
                              className={
                                moreDashboard ? "up-arrow" : "down-arrow"
                              }
                              alt={"more"}
                            />
                            {isExpanded && (
                              <>{moreDashboard ? "Less" : "More"}</>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.title === "Saved Filters" ? (
                    <div>
                      {showFilter && (
                        <>
                          {dropdownMenu ? (
                            <>
                              <div>
                                <div
                                  className={
                                    moreSavedFilter
                                      ? "nav-child-full"
                                      : "nav-child-menu"
                                  }
                                >
                                  {item.children?.map((child) => {
                                    return (
                                      <div
                                        className={` ${
                                          isExpanded
                                            ? "nav-child"
                                            : "collapse-nav-child"
                                        }`}
                                      >
                                        <NavLink
                                          key={child.title}
                                          to={child.path}
                                          onClick={(e) =>
                                            handleClick(
                                              e,
                                              "VehiclePopulation",
                                              child.path
                                            )
                                          }
                                          className={({ isActive }) =>
                                            isActive ? "is-active" : ""
                                          }
                                        >
                                          <span
                                            className={`icon nav-icons ${child.iconName}`}
                                          ></span>
                                          {isExpanded && (
                                            <span className="nav-items">
                                              {child.title}
                                            </span>
                                          )}
                                        </NavLink>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div
                                  className={
                                    isExpanded
                                      ? "more-nav-menu"
                                      : "more-nav-menu collapse"
                                  }
                                  onClick={() => handleMore(item.title)}
                                >
                                  <img
                                    src={downArrowThin}
                                    className={
                                      moreSavedFilter
                                        ? "up-arrow"
                                        : "down-arrow"
                                    }
                                    alt={"More"}
                                  />
                                  {isExpanded && (
                                    <>{moreSavedFilter ? "Less" : "More"}</>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </>
        ) : vinNumberFromRedux !== null &&
          NavigationType === "IndividualVehicle" ? (
          <>
            {menuItmes?.length &&
              menuItmes.map((item) => (
                <div
                  className={` ${
                    isExpanded ? "nav-items-icon" : "collapse-nav-icon"
                  }`}
                >
                  <NavLink
                    key={item.title}
                    to={item.path}
                    className={({ isActive }) => (isActive ? "is-active" : "")}
                  >
                    <span className={`icon nav-icons ${item.iconName}`}></span>
                    {isExpanded && (
                      <span className="nav-items">{item.title}</span>
                    )}
                  </NavLink>
                </div>
              ))}
          </>
        ) : (
          <></>
        )}

        {NavigationType === "AdminDashBoard" && menuItmes?.length ? (
          menuItmes.map((item) => (
            <div
              className={` ${
                isExpanded ? "nav-items-icon" : "collapse-nav-icon"
              }`}
            >
              <NavLink
                key={item.title}
                to={item.path}
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <span className={`icon nav-icons ${item.iconName}`}></span>
                {isExpanded && <span className="nav-items">{item.title}</span>}
              </NavLink>
            </div>
          ))
        ) : (
          <></>
        )}

        {/* <Outlet /> */}
      </div>
    </>
  );
};
