import React from "react";
import moment from "moment";

const LeftVehileInformation = ({ vehicleInfo }) => {
  return (
    <div className="left-container">
      <div className="vehicle-info-container">
        <p>VIN number</p>:<p>{vehicleInfo?.vinNumber}</p>
      </div>
      <div className="vehicle-info-container">
        <p>Manufacturer</p>:<p>{vehicleInfo?.make}</p>
      </div>
      <div className="vehicle-info-container">
        <p>Model</p>:<p>{vehicleInfo?.model}</p>
      </div>
      <div className="vehicle-info-container">
        <p>Model year</p>:<p>{vehicleInfo?.modelYear}</p>
      </div>
      <div className="vehicle-info-container">
        <p>Last update</p>:
        {vehicleInfo?.lastUpdated && (
          <p>
            {/* {moment(vehicleInfo?.lastUpdated).format(
              "HH:mm:ss, MMMM Do, YYYY  "
            )} */}
             {moment().subtract(6, 'hours').format(
              "HH:mm:ss, MMMM Do, YYYY  "
             )}
          </p>
        )}
      </div>
      <div className="vehicle-info-container">
        <p>Odometer</p>:<p>{vehicleInfo?.odometer}</p>
      </div>
      <div className="vehicle-info-container">
        {/* <p>Vehicle System Sw ver</p>:<p>{vehicleInfo?.systemSoftwareVersion}</p> */}
        <p>Vehicle System Sw ver</p>:<p>{"3.5.104"}</p>
      </div>
    </div>
  );
};

export default LeftVehileInformation;
