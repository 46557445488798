import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer text-align-center">
      © 2024 Autonostix360 All rights reserved.
    </div>
  );
};

export default Footer;
