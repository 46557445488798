import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import "../VehiclePopulation.scss";
import { VehicleCard } from "../components/VehicleCard";
import geoMapping from "../assets/geo-spatial-mapping-blue.svg";
import RightVehiclePagesContainerPrognostic from "./RightVehiclePagesContainerPrognostic";
import plusIcon from "../assets/plus-icon.svg";
import compare_vin from "../assets/compare-with-vin-icon.svg";
import { Button } from "../../../components/common/Button/Button";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import SearchIcon from "../../../assets/search-icon-white.svg";
import useCloseOnClickOutside from "../../../hooks/useCloseOnClickOutside";
import ModalPopup from "../../../components/common/Modal/ModalPopup";
import { useDispatch } from "react-redux";
import {
  addFirstFilter,
  addSecondFilter,
  addVinFilter,
} from "../../../store/actions/fetchActions";
import close from "../../../assets/close.svg";

const ReportForPrognostic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDetails, setShowDetails] = useState(false);
  const [progFilter, setProgFilter] = useState(null);

  const [defFilterData, setDefFilterData] = useState(null);
  const displayCard = location.pathname.includes(
    "/vehicle-population/prognostic/warranty-scorecard"
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [data, setData] = useState([]);
  const [comparePopup, setComparePopup] = useState(false);
  const [errorModel, setErrorModel] = useState("");
  const [compareVinNumber, setCompareVinNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filterCardname, setFilterCardname] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [filterErrModel, setFilterErrModel] = useState("");

  useEffect(() => {
    if (location.pathname === "/vehicle-population/prognostic") {
      navigate("/vehicle-population/prognostic/top-10-dtc", { replace: true });
      localStorage.removeItem("VP Diagnostic/Prognostic");
      localStorage.setItem("VP Diagnostic/Prognostic", 1);
    }
  }, [location.pathname]);

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );

    const selectedRulData = localStorage.getItem("rul");
    const RULlowerLimitData = localStorage.getItem("lowerLimitData");
    const RULupperLimitData = localStorage.getItem("upperLimitData");
    let params = {};
    switch (selectedRulData) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(RULlowerLimitData),
          toRulMiles: parseInt(RULupperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(RULlowerLimitData),
          toRulEngineRunTime: parseInt(RULupperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(RULlowerLimitData),
          toRulHours: parseInt(RULupperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(RULlowerLimitData),
          toRulKeyStarts: parseInt(RULupperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {};
      }
    }

    const ProgFilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
      ...params,
    };
    setProgFilter(ProgFilterSelectedValues);
  }, []);

  const HandleClick = () => {
    const FilterSelectedValues = localStorage.getItem("isFilterSaved");
    if (FilterSelectedValues !== "true" && FilterSelectedValues !== null) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigate("/vehicle-population/FormLayout", {
        state: { path: location.pathname },
      });
      localStorage.removeItem("filterSelectedValues");
    }
  };

  useEffect(() => {
    if (userInput.length > 3) {
      getFilteredData();
    } else {
      getFilteredData();
    }
  }, [userInput]);

  let selectRef = useCloseOnClickOutside(() => {
    setShowDropdown(false);
  });

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setDefFilterData(response.data);
      })
      .catch((er) => {
        throw er;
      });
    //getFilteredData();
  }, []);

  const getFilteredData = () => {
    const url = `${VehiclePopulationDashBoard.search_filter}`;
    let params = {
      make: "Ford",
      searchValue: userInput,
      filterType: "All",
      page: 0,
      size: 50,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data?.content || []);
          // setApiError(false);
        }
        if (response.status === 401) {
          // setErrorMsg(response.data?.details || "You are not authorized.");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // setApiError(true);
        } else {
          // setApiError(true);
        }
      });
  };

  const handleCompare = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearch = (e) => {
    setUserInput(e.target.value);
  };

  const handleFilterNameClick = (value) => {
    let filteredValues = {};
    if (Object.keys(progFilter).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = progFilter;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(value));
    dispatch(addVinFilter(null));
    navigate("/vp-saved-filter/comparision/GeneratedDashboard/prognostics", {
      replace: true,
    });
  };

  const handleCompareVin = () => {
    setComparePopup(true);
  };

  const handleCompareVinNumber = (value) => {
    setCompareVinNumber(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setErrorModel("VIN number should not contain special characters");
    } else if (value.includes(" ")) {
      setErrorModel("VIN number should not contain space");
    } else {
      setErrorModel("");
    }
  };

  const ModelCloseButton = () => {
    setComparePopup(false);
  };

  const submitVinCompare = () => {
    let filteredValues = {};
    if (Object.keys(progFilter).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = progFilter;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(null));
    dispatch(addVinFilter(compareVinNumber));
    navigate("/vp-saved-filter/comparision/GeneratedDashboard/prognostics", {
      replace: true,
    });
  };

  const modalStyle = {
    position: "fixed",
    background: "#000000aa",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1111,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const modalContentStyle = {
    textAlign: "left",
    position: "fixed",
    top: "50%",
    left: "50%",
    width: 400,
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    display: "",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11111,
  };

  const closeModelPopup = () => {
    setShowModal(false);
    setSaveModal(false);
  };

  const handleSaveFilter = () => {
    setFilterCardname("");
    setShowModal(false);
    setSaveModal(true);
  };

  const handleRmFilter = () => {
    setShowModal(false);
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  const handleInputCardnameChange = (e) => {
    setFilterCardname(e.target.value);
    validateInput(e.target.name, e.target.value);
  };

  const validateInput = (name, value) => {
    let errors = "";
    switch (name) {
      case "filterCardname":
        errors =
          value.length === 0
            ? "Filter name should not be empty"
            : value.length >= 30
            ? "Filter name should not be more than 30 characters"
            : "";
        break;
      default:
        break;
    }
    setFilterErrModel(errors);
  };

  const handleSaveCancel = () => {
    setSaveModal(false);
    setFilterErrModel("");
  };

  const saveToFilterCard = async () => {
    let params = {};
    let selectedData = JSON.parse(localStorage.getItem("selectedData"));
    let selectedDiagonosticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    let rul = localStorage.getItem("rul");
    let lowerLimitDataLS = localStorage.getItem("lowerLimitData");
    let upperLimitDataLS = localStorage.getItem("upperLimitData");
    let lowerLimitData = localStorage
      .getItem("lowerLimitData")
      ?.slice(1, lowerLimitDataLS.length - 1);
    let upperLimitData = localStorage
      .getItem("upperLimitData")
      ?.slice(1, upperLimitDataLS.length - 1);
    if (rul !== null) {
      switch (rul) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitData),
            toRulEngineRunTime: parseInt(upperLimitData),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitData),
            toRulHours: parseInt(upperLimitData),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitData),
            toRulKeyStarts: parseInt(upperLimitData),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
        }
      }
    }

    if (filterCardname !== "") {
      const url = `${VehiclePopulationDashBoard.saved_filters}`;
      let filterType = "Prognostics";
      let filterData = {
        ...selectedData, //for general data
        ...selectedDiagonosticsData, // for diagnostics data
        ...params, //for rul data
      };
      let data = {
        make: "Ford",
        filterName: filterCardname,
        filterType: filterType,
        ...filterData,
      };
      return mainAxiosInstance
        .post(url, data)
        .then((response) => {
          if (response.status === 200) {
            removeFilterAndRedirect();
            setSaveModal(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setFilterErrModel("You are not authorized");
          } else {
            setFilterErrModel("Something went wrong ");
          }
        });
    }
  };

  const removeFilterAndRedirect = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  return (
    <div className="vehicle-population-container">
      <div className="top-button-container">
        <div className="inp">
          {progFilter != null && Object?.keys(progFilter).length > 0 && (
            <div
              className="display-selected-data"
              title={Object.keys(progFilter)
                .filter(
                  (key) =>
                    key !== "id" &&
                    key !== "userRole" &&
                    key !== "organizationId" &&
                    key !== "filterName" &&
                    key !== "filterType" &&
                    key !== "username" &&
                    key !== "type" &&
                    key !== "fromMiles" &&
                    key !== "toMiles" &&
                    key !== "lastUpdated" &&
                    key !== "rul" &&
                    key !== "module" &&
                    progFilter[key] !== null
                )
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .join("_")}
            >
              {Object.keys(progFilter)
                .filter(
                  (key) =>
                    key !== "id" &&
                    key !== "userRole" &&
                    key !== "organizationId" &&
                    key !== "filterName" &&
                    key !== "filterType" &&
                    key !== "username" &&
                    key !== "type" &&
                    key !== "fromMiles" &&
                    key !== "toMiles" &&
                    key !== "lastUpdated" &&
                    key !== "rul" &&
                    key !== "module" &&
                    progFilter[key] !== null
                )
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .filter((elements) => elements != null)
                .join("_")}
            </div>
          )}
        </div>
        <div className="top-button-container-icons">
          <p className="plus-icon-button" onClick={HandleClick}>
            <img className="" src={plusIcon} alt="wa" />
          </p>
          <p className="compare-with-vin-button" onClick={handleCompareVin}>
            Compare with VIN
            <img className="" src={compare_vin} alt="wa" />
          </p>
          <p className="compare-button" onClick={handleCompare}>
            Compare
            <img className="" src={compare_vin} alt="wa" />
          </p>
          {showDropdown ? (
            <div className="compare-dropdown" ref={selectRef}>
              <div className="compare-dropdown-search">
                <img src={SearchIcon} alt="search" className="search_image" />
                <input
                  type="text"
                  className="search_field"
                  placeholder="Search for attribute"
                  value={userInput}
                  onChange={handleSearch}
                  // onKeyPress={onEnter}
                />
              </div>
              <div className="compare-dropdown-values">
                {data.map((values) => {
                  return (
                    <div
                      className="compare-dropdown-value"
                      onClick={() => handleFilterNameClick(values)}
                    >
                      {values.filterName}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {comparePopup ? (
        <>
          <ModalPopup
            firstParagraph={"Enter VIN number here"}
            firstButton="Cancel"
            secondButton="Compare"
            onValueChange={handleCompareVinNumber}
            closeModal={ModelCloseButton}
            errorModel={errorModel}
            onClick={submitVinCompare}
            inputValue={"DH34ASD7SDFF84742"}
            placeholder={"Enter the VIN number"}
            buttonClassName={
              compareVinNumber.length > 0 && errorModel.length === 0
                ? "btn-primary-blue"
                : "btn-primary-blue disabled"
            }
            disableSecondButton={
              compareVinNumber.length > 0 && errorModel.length === 0
                ? false
                : true
            }
          />
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
          }}
        >
          <div className="Top-container">
            <NavLink
              to="/vehicle-population/prognostic/top-10-dtc"
              className={({ isActive }) =>
                isActive ? "white-container-tab" : "orange-container-tab"
              }
            >
              <VehicleCard
                backgroundColor="#E3F5E3"
                text="Top 10 DTC  "
                handleClick={""}
                graphContainer="Up-down"
              />
            </NavLink>
            <NavLink
              to="/vehicle-population/prognostic/top-10-Non-dtc"
              className={({ isActive }) =>
                isActive ? "white-container-tab" : "orange-container-tab"
              }
            >
              <VehicleCard
                backgroundColor="#E3F5E3"
                text="Top 10 Non-DTC"
                secondText=""
                handleClick={""}
                graphContainer="Up-down"
              />
            </NavLink>
            <NavLink
              to="/vehicle-population/prognostic/population-setting-dtc"
              className={({ isActive }) =>
                isActive ? "white-container-tab" : "orange-container-tab"
              }
            >
              <VehicleCard
                backgroundColor="#E3F5E3"
                text="% of population "
                secondText="setting DTC"
                thirdText="(By Mileage)"
                handleClick={""}
                graphContainer="Down-up"
              />
            </NavLink>
            <NavLink
              to="/vehicle-population/prognostic/warranty-scorecard"
              className={({ isActive }) =>
                isActive ? "white-container-tab" : "orange-container-tab"
              }
            >
              <VehicleCard
                backgroundColor="#E3F5E3"
                text="DTC Warranty "
                secondText="Scorecard"
                handleClick={""}
                graphContainer="Up-down"
              />
            </NavLink>
            <NavLink
              to="/vehicle-population/prognostic/geo-spatial-mapping"
              className={({ isActive }) =>
                isActive ? "white-container-tab" : "orange-container-tab"
              }
            >
              <VehicleCard
                img={geoMapping}
                backgroundColor="#E3F5E3"
                text="Geo-Spatial"
                secondText="mapping"
                handleClick={""}
              />
            </NavLink>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
        <RightVehiclePagesContainerPrognostic />
      </div>
      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <div className="discard-card-modal-close" onClick={closeModelPopup}>
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>
              Do you want to discard the changes
            </p>
            <div className="discardBtns">
              <button onClick={handleSaveFilter} className="btn-secondary-blue">
                No
              </button>
              <button
                onClick={handleRmFilter}
                style={{ width: 80, marginLeft: "1em" }}
                className="btn-primary-blue"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {saveModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <span className="error-text">{filterErrModel}</span>
            <div className="discard-card-modal-close" onClick={closeModelPopup}>
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>Save this filter as</p>
            <input
              name="filterCardname"
              type="text"
              className="saveInput"
              value={filterCardname}
              onChange={handleInputCardnameChange}
              maxLength={30}
            />
            <div className="modBtns">
              <button onClick={handleSaveCancel} className="btn-secondary-blue">
                Cancel
              </button>
              <button
                onClick={saveToFilterCard}
                className={
                  filterCardname.length >= 1
                    ? "btn-primary-blue"
                    : "btn-primary-blue disabled"
                }
                style={{ width: 100, marginLeft: "1em" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        id="for-warrantyscorecard"
        style={{ display: `${displayCard ? "" : "none"}` }}
      >
        <Button
          title={showDetails ? "Hide Details" : "Show Details"}
          handleClick={() => setShowDetails(!showDetails)}
          styleClasses="showdetails"
        />
        {showDetails && (
          <>
            <div className="mainTable-container">
              <div className="firstTable">
                <table>
                  <thead>
                    <tr>
                      <th colspan="2">DTC Warranty Scorecard Factors</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Failure Reports:</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>Failure Rate:</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>Repeat Failures</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>Cost</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td>Severity</td>
                      <td>20%</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <div className="bmt-text">
                  <p>Total</p>
                  <p>85%</p>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div className="secondTable" style={{ marginLeft: "5px" }}>
                    <table>
                      <thead>
                        <tr>
                          <th colspan="2">Failure reports</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>1-10</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>11-15</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>16-20</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>21-30</td>
                          <td>8</td>
                        </tr>
                        <tr>
                          <td>&gt;31</td>
                          <td>10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="thirdTable" style={{ marginLeft: "5px" }}>
                    <table>
                      <thead>
                        <tr>
                          <th colspan="2">Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>1-10</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>11-15</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>16-20</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>21-30</td>
                          <td>8</td>
                        </tr>
                        <tr>
                          <td>&gt;31</td>
                          <td>10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="fourthTable" style={{ marginLeft: "5px" }}>
                    <table>
                      <thead>
                        <tr>
                          <th colspan="2">Severity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Poor Performance</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>Intermittent</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>Limp home mode</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Customer stranded</td>
                          <td>8</td>
                        </tr>
                        <tr>
                          <td>Safety/Emissions</td>
                          <td>10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div className="btmText">
                    <p>Repeat Failure</p>
                    <p className="equalsTo1">=</p>
                    <p className="formula1">
                      Failure Reports /Vehicles affected
                    </p>
                  </div>
                  <div className="btmText">
                    <p>Failure Rate</p>
                    <p className="equalsTo2">=</p>
                    <p className="formula2">Vehicles affected/Population </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportForPrognostic;
